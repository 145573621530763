<template>
    <div class="slider-container">
        <div class="slider">
            <div class="slides" @mouseover="pauseSlide" @mouseleave="unpauseSlide">
                <div
                    v-for="(slide, index) in sliderData" :key="index" 
                    class="slide" 
                    :class="{
                        'active': index == activeSlide
                    }"
                    :style="{ 'background-image': 'url(' + slide.photo.url + ')' }">
                    <div class="overlay"></div>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            category: {
                type: String,
                default: ''
            },
            limit: {
                type: Number,
                default: -1
            }
        },

        data() {
            return {
                sliderData: [],
                activeSlide: 0,
                paused: false
            }
        },

        created () {
            let category = (this.category != '' ? 'category=' + this.category : '');
            let limit = (this.limit != '' ? 'limit=' + this.limit : '');
            let request = '';

            if(category != '' || limit != -1){
                request = '?' + (category != '' ? category : '');
                if(category != '' && limit != -1){
                    request += '&' + limit;
                }else{
                    request += (limit != '' ? limit : '');
                }
            }

            fetch("/wp-json/kerigansolutions/v1/slider")
                .then(r => r.json())
                .then(response => {
                    this.sliderData = response; 
                });
        },

        mounted () {
            setInterval(() => { if(this.paused === false){ this.nextSlide() } }, 6000);
        },

        methods: {
            nextSlide(){
                if(this.activeSlide === this.sliderData.length-1){
                    this.activeSlide = -1
                }
                this.activeSlide++
            },

            prevSlide(){
                this.activeSlide--
                if(this.activeSlide === -1){
                    this.activeSlide = this.sliderData.length-1
                }
            },

            clickNext(){
                this.nextSlide()
                this.pauseSlide()
            },

            clickPrev(){
                this.prevSlide()
                this.pauseSlide()
            },

            pauseSlide(){
                this.paused = true;
            },

            unpauseSlide(){
                this.paused = false;
            }

        }
    }
</script>
<style lang="scss">
.slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    .slides {
        flex-grow: 1;
    }
}

.slide {
    width:100%;
    opacity: 0;
    transition: opacity linear 2s;
    position: absolute;
    left:0; right: 0; top: 0; bottom: 0;
    height: auto;
    display: flex;

    .slide-overlay {
        position: absolute;
        z-index: 2;
        left:0; right: 0; top: 0; bottom: 0;
        background-color: rgba(0,0,0,.4);
    }

    &.active {
        opacity: 1;
        display: flex;
    }
}
</style>