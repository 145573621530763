<template>
        <div>
            <a 
                v-for="(icon) in socialData"
                :key="icon.index" 
                v-html="icon['icon']" 
                :href="icon['link']"
                :style="'width:' + size + 'px; margin:' + margin + 'rem;'"
                target="_blank"
                :aria-label="'follow us on ' + icon['link']"
            ></a>
        </div>
</template>

<script>
    export default {
        props: {
            size: {
                type: Number,
                default: 30
            },
            margin: {
                type: Number,
                default: 0
            }
        },

        data() {
            return {
                socialData: []
            }
        },

        mounted () {
            fetch("/wp-json/kerigansolutions/v1/social-links/")
                .then(r => r.json())
                .then(response => {
                    this.socialData = response; 
                });
        }
    }
</script>