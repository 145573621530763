<template>
    <div class="menu-container p-4 flex-grow-1" :class="{ 'loaded': loaded, 'd-none': !loaded }" >
        <div class="close-section text-right pt-2" >
            <i @click="closeMenu()" class="fa fa-times text-white display-2"></i>
        </div>
        <div v-for="(navitem, index) in mobileNavData" v-bind:key="index">
            <div class="pt-2 d-flex justify-content-between" >
                <a 
                    :href="navitem.url" 
                    class="mobile-nav-link font-weight-bold" 
                    :target="navitem.target != '' ? navitem.target : '_self'" 
                    v-html="navitem.title"
                ></a>
                <span class="nav-icon text-primary" v-if="navitem.children.length > 0" @click="toggleSubMenu(index)">
                    <i class="fa" :class="{
                        'fa-plus-circle': !navitem.subMenuOpen,
                        'fa-minus-circle': navitem.subMenuOpen
                        }" ></i>
                </span>
            </div>
            <div class="dropdown-container my-2" v-if="navitem.subMenuOpen" >
                <div 
                    v-for="(child, i) in navitem.children" 
                    v-bind:key="i" 
                >
                    <a 
                        :href="child.url" 
                        :class="'py-1 d-block text-accent'" 
                        :target="child.target != '' ? child.target : '_self'" 
                        v-html="child.title"
                    ></a>
                </div>
            </div>
        </div>
        <a 
            class="btn btn-accent rounded-pill text-secondary mt-4 mb-3 font-weight-bold" 
            href="#" 
            @click="goToSection('#consultation')" 
        >Request a Consultation</a>
        <div class="close-section d-flex justify-content-between align-items-center pt-3" >
            <social-icons 
                :size="40" 
                :margin=".25" 
                class="d-flex social text-accent" 
            ></social-icons>
        </div>
    </div>
</template>
<script>
import vueScrollto from 'vue-scrollto'

export default {
    props: {
        'menu': {
            type: String,
            default: ''
        }
    },

    data(){
        return {
            loaded: false,
            mobileNavData: [],
        }
    },

    watch: {
        endpoint: function () {
            this.mobileNavData = [];
            this.getMenu();
        }
    },

    mounted() {
        this.getMenu();
    },

    methods: {

        getMenu() {
            let mobileNav = this;

            fetch("/wp-json/kerigansolutions/v1/navigation-menu?slug=" + this.menu)
            .then(r => r.json())
            .then(response => {
 
                mobileNav.mobileNavData = Object.keys(response).map((item) => {
                    response[item].subMenuOpen = false;
                    if(response[item].children.length > 0){
                        response[item].subMenuOpen = true;
                    }
                    return response[item];
                })
                
            }).then(() => {
                if(mobileNav.mobileNavData.length < 0){
                    mobileNav.getMenu()
                }
                
                mobileNav.loaded = true;
            })
    
        },
        
        toggleSubMenu(navitem){
            this.mobileNavData[navitem].subMenuOpen = !this.mobileNavData[navitem].subMenuOpen;
        },

        closeMenu() {
            // this.loaded = false;
            this.$emit('closemenu', this.menu);
        },

        goToSection(to) {
            vueScrollto.scrollTo(to);
            this.closeMenu();
        }
    }
}
</script>
