<template>
    <div v-if="loaded" >
        <div 
            class="post-item d-flex text-decoration-none align-items-lg-center w-100 flex-column flex-lg-row mb-3" 
            v-for="post in posts" 
            :key="post.index" 
        >
            <div class="d-flex-grow-1 pr-3" >
                <p class="font-weight-bold m-md-0" v-html="post.title.rendered"></p>
                <div class="d-none d-md-block" v-html="post.excerpt.rendered" ></div>
            </div>
            <p><a 
                target="_blank"
                class="btn btn-outline-primary font-weight-bold rounded-pill"
                :href="post.link"
                >READ&nbsp;MORE</a></p>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        limit: {
            type: Number,
            default: 6
        }
    },

    data() {
        return {
            loaded: false,
            posts: {}
        }
    },

    created() {
        this.getEvents()
    },

    methods: {
        getEvents(){
            fetch("/wp-json/wp/v2/posts", {
                    params: {
                        context: 'view',
                        page: 1,
                        per_page: this.limit
                    }
                })
                .then(r => r.json())
                .then(response => {
                    this.posts = response; 
                    this.loaded = true;
                });
        }
    }

}
</script>
<style scoped>

</style>