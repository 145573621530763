// if (!window._babelPolyfill) {
//     window._babelPolyfill = require('babel-polyfill');
// }

// window.Vue = require('vue');
// window.axios = require('axios');

// import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';

// window.http = axios.create({
// 	baseURL: '/',
// 	headers: { 'Cache-Control': 'no-cache' },
// 	adapter: throttleAdapterEnhancer(axios.defaults.adapter, { threshold: 2 * 1000 })
// });

import Vue from 'vue'

import VueParallaxJs from 'vue-parallax-js'
Vue.use(VueParallaxJs)

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
    preLoad: 1.1,
    error: '',
    loading: '',
    attempt: 1
})

import vueScrollto from 'vue-scrollto'
Vue.use(vueScrollto, {
    container: "body",
    duration: 1000,
    easing: "ease",
    offset: -100,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})

Vue.component('social-icons', require('./components/SocialMediaIcons.vue').default);
Vue.component('kma-slider', require('./components/KMASliderModule.vue').default);
Vue.component('mobile-menu', require('./components/MobileNavMenu.vue').default);
Vue.component('contact-form', require('./components/ContactForm.vue').default);
Vue.component('invisible-recaptcha', require('./components/InvisibleRecaptcha.vue').default);
Vue.component('posts-feed', require('./components/PostsFeed.vue').default);

const app = new Vue({
    el: '#app',

    data: {
        isScrolling: false,
        scrollPosition: 0,
        mounted: false,
        mobileMenuOpen: false
    },

    methods: {
        handleScroll () {
            this.scrollPosition = (window.scrollY !== undefined ? window.scrollY : window.pageYOffset);
            this.isScrolling = (this.scrollPosition > 0)
        },

        toggleMenu() { 
            this.mobileMenuOpen = ! this.mobileMenuOpen;
        }, 

        checkUrl(){
            if(window.location.hash.substring(0, 1) === "#"){
                vueScrollto.scrollTo(window.location.hash);
            }
        }
    },

    mounted () {
        this.handleScroll();
        this.mounted = true;
        this.checkUrl();
    },

    created () {
        window.addEventListener('scroll', this.handleScroll);
    },

    destroyed () {
        window.removeEventListener('scroll');
    }

})
