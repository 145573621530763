<template>
    <div>
        <p class="alert alert-success" v-if="success == true">Thank you for reaching out. We'll get back with you as soon as possible.</p>
        <form v-if="!success">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="sr-only" for="name-field">Name</label>
                        <input
                            type="text"
                            id="name-field"
                            class="form-control border-1"
                            placeholder="Name"
                            v-model="form.name"
                            required
                        >
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="sr-only" for="email-field">Email Address</label>
                        <input
                            type="email"
                            id="email-field"
                            class="form-control border-1"
                            placeholder="Email"
                            v-model="form.email"
                            required
                        >
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label class="sr-only" for="phone-number-field">Phone Number</label>
                        <input
                            type="tel"
                            id="phone-number-field"
                            class="form-control border-1"
                            placeholder="Phone"
                            v-model="form.phone"
                            required
                        >
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="sr-only" for="message-field">Message</label>
                        <textarea
                            id="message-field"
                            class="form-control border-1"
                            placeholder="Message"
                            v-model="form.comments"
                        >
                        </textarea> 
                    </div>
                </div>
                <div class="col-12">
                    <p class="alert alert-danger" v-if="hasError == true">Errors were found in your entry. Please complete the required fields and try again.</p>
                    
                    <invisible-recaptcha 
                        :sitekey="sitekey" 
                        :validate="process"
                        :callback="formSubmitted"
                        :disabled="processing"
                        class="d-inline-block"
                        buttonclass="btn btn-accent font-weight-bold rounded-pill overflow-hidden" 
                        >Send Message &nbsp; <img src= "/images/angle-right.svg" class="pb-1" aria-hidden="true" alt=""/>
                    </invisible-recaptcha>
                    <div 
                        v-if="!processing"
                        class="p-3 text-white font-italic d-inline-block" 
                        style="font-size:.9em;">
                        All&nbsp;fields&nbsp;required.</div>
                    <div
                        v-if="processing"
                        class="p-3 text-white font-italic d-inline-block" 
                        style="font-size:.9em;" 
                        >Sending&nbsp;message...</div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import ContactForm from '../models/contact-form';
    export default {

        props: {
            sitekey: {
                type: String,
                default: ''
            }
        },

        data () {
            return {
                processing: false,
                form: new ContactForm({
                    name: '',
                    email: '',
                    phone: '',
                    comments: '',
                    url: '/wp-json/kerigansolutions/v1/submit-contact-form'
                })
            }
        },
        watch: {
            hasError: function (newVal, oldVal) {
                if(newVal == true){
                    this.processing = false;
                }
            }
        },
        computed: {
            hasError: function() {
                return this.form.hasError;
            },
            errorCode: function () {
                return this.form.errorCode;
            },
            success: function () {
                return this.form.success;
            }
        },
        methods: {
            formSubmitted () {
                this.form.submit();
                this.processing = false;
            },
            process () {
                this.form.hasError = false;
                this.processing = true;
            }
        }
    }
</script>